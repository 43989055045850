import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import './Contact.css'; // Importa tu archivo CSS

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  // Manejar cambios en el formulario
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value
    });
  };

  // Manejar el envío del formulario
  const handleSubmit = async (e) => {
    e.preventDefault();

    // URL de la API, puede usar una variable de entorno o un valor por defecto
    const apiUrl = process.env.REACT_APP_API_URL || 'https://suriyaco.ar/api/send';

    console.log('API_URL:', apiUrl);
    console.log('Form Data:', formData);

    try {
      const response = await axios.post(apiUrl, formData, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      
      Swal.fire({
        icon: 'success',
        title: 'Correo enviado',
        text: response.data.message,
        showConfirmButton: false,
        timer: 1500
      });
    } catch (error) {
      if (error.response) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Error al enviar el correo: ' + error.response.data.message,
        });
      } else if (error.request) {
        console.error('Error en la solicitud:', error.request);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'No se recibió respuesta del servidor.',
        });
      } else {
        console.error('Error en la configuración de la solicitud:', error.message);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Error: ' + error.message,
        });
      }
    }
  };

  return (
    <div className="contact-container">
      <h2>Contacto</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Nombre</label>
          <input
            type="text"
            className="form-control"
            id="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Ingrese su nombre"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            className="form-control"
            id="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Ingrese su email"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">Mensaje</label>
          <textarea
            className="form-control"
            id="message"
            value={formData.message}
            onChange={handleChange}
            rows="3"
            placeholder="Ingrese su mensaje"
            required
          ></textarea>
        </div>
        <button type="submit" className="btn btn-primary">
          Enviar
        </button>
      </form>
    </div>
  );
}

// Exporta el componente al final del archivo, fuera de cualquier bloque o función
export default Contact;
