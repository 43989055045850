import React from 'react';
import './EquipmentPage.css';

// Imágenes del equipamiento (asegúrate de colocar las imágenes en la carpeta correcta)
import wingtra from './images/wingtra.jpg';
import rs10 from './images/rs10.jpg';
import topcon from './images/topcon.jpg';

const EquipmentPage = () => {
  const equipment = [
    {
      name: 'Wingtra',
      description: 'El Wingtra es un dron de despegue y aterrizaje vertical (VTOL) diseñado para realizar levantamientos topográficos de alta precisión y grandes áreas. Es ideal para proyectos de cartografía y agricultura de precisión.',
      image: wingtra
    },
    {
      name: 'RS10 SLAM Scanner',
      description: 'El RS10 SLAM Scanner es un escáner portátil que utiliza tecnología de Simultaneous Localization and Mapping (SLAM) para mapear entornos en tiempo real con alta precisión.',
      image: rs10
    },
    {
      name: 'GPS Topcon Glonass',
      description: 'El GPS Topcon Glonass ofrece un sistema de posicionamiento de alta precisión compatible con satélites GPS y Glonass, lo que lo hace ideal para levantamientos topográficos y geodésicos.',
      image: topcon
    }
  ];

  return (
    <div className="equipment-page">
      <h1>Equipamiento</h1>
      <div className="equipment-list">
        {equipment.map((item, index) => (
          <div className="equipment-card" key={index}>
            <img src={item.image} alt={item.name} className="equipment-image" />
            <h2>{item.name}</h2>
            <p>{item.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default EquipmentPage;
