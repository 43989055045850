import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import gisImage from './images/gis.jpg'; // Ajusta las rutas si es necesario
import droneImage from './images/drone.jpg';
import arvrImage from './images/arvr.jpg';
import './Services.css'; // Importa el archivo CSS

const Services = () => {
  return (
    <div>
      <h1 className="text-center my-4">Nuestros Servicios</h1>
      <Row>
        {/* GIS Development */}
        <Col md={4}>
          <Card className="mb-4 shadow-sm">
            <Card.Img variant="top" src={gisImage} alt="GIS Development" />
            <Card.Body>
              <Card.Title className="text-primary">
                Desarrollo de Software GIS y Mapas Inteligentes
              </Card.Title>
              <Card.Text>
                En <strong>Suriyaco Valley</strong>, desarrollamos aplicaciones personalizadas basadas en 
                Sistemas de Información Geográfica (GIS) que permiten a nuestros clientes visualizar y analizar 
                datos geoespaciales de manera eficiente.
              </Card.Text>
              <Card.Text>
                <u>Ofrecemos soluciones como:</u>
                <ul>
                  <li>Mapas Inteligentes con análisis en tiempo real.</li>
                  <li>Planificación urbana y gestión ambiental.</li>
                  <li>Monitoreo de recursos naturales.</li>
                  <li>Optimización de rutas de transporte.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        {/* Levantamientos Masivos con Drones y LIDAR */}
        <Col md={4}>
          <Card className="mb-4 shadow-sm">
            <Card.Img variant="top" src={droneImage} alt="Drones and LIDAR" />
            <Card.Body>
              <Card.Title className="text-primary">
                Levantamientos Masivos con Drones y LIDAR
              </Card.Title>
              <Card.Text>
                Realizamos levantamientos a gran escala utilizando drones Wingtra y tecnología LIDAR con SLAM RS10, 
                tanto en exteriores como interiores, generando modelos 3D de alta precisión en tiempo récord.
              </Card.Text>
              <Card.Text>
                <u>Beneficios:</u>
                <ul>
                  <li>Modelos 3D precisos.</li>
                  <li>Rápida obtención de datos con análisis detallado.</li>
                  <li>Aplicaciones en minería, construcción y topografía.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        {/* Realidad Aumentada y Virtual */}
        <Col md={4}>
          <Card className="mb-4 shadow-sm">
            <Card.Img variant="top" src={arvrImage} alt="AR/VR Development" />
            <Card.Body>
              <Card.Title className="text-primary">
                Aplicaciones en Realidad Aumentada y Virtual
              </Card.Title>
              <Card.Text>
                Desarrollamos experiencias inmersivas en Realidad Aumentada (AR) y Realidad Virtual (VR), 
                creando escenarios personalizados para visualizaciones avanzadas.
              </Card.Text>
              <Card.Text>
                <u>Aplicaciones destacadas:</u>
                <ul>
                  <li>Mapas urbanos y mineros en 3D.</li>
                  <li>Simuladores de operaciones industriales.</li>
                  <li>Proyectos de planificación y diseño arquitectónico.</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Services;
