import React from 'react';
import './SuccessStory.css';
import { Container, Row, Col, Card } from 'react-bootstrap';

function SuccessStory() {
  const story = {
    title: 'Implementación y Actualización Catastral',
    subtitle: 'Un caso de éxito en la gestión urbana y la recuperación de la renta inmobiliaria',
    description: [
      'La implementación de gemelos digitales para mejorar la gestión urbana y reducir costos operativos ha sido un paso significativo en el desarrollo de ciudades inteligentes. Al integrar la tecnología de gemelos digitales, se ha logrado una representación virtual de la infraestructura urbana que permite optimizar los recursos y aumentar la eficiencia en la toma de decisiones.',
      'El Wingtra, conocido por su capacidad para realizar vuelos de despegue y aterrizaje vertical (VTOL), ha facilitado un relevamiento aéreo de alta precisión, capturando imágenes detalladas y datos topográficos de la infraestructura urbana.',
      'Ventajas del Relevamiento con Wingtra para la Gestión Urbana:',
      '1. Mayor Precisión en la Planificación.',
      '2. Eficiencia en la Ejecución de Proyectos.',
      '3. Reducción de Costos Operativos.',
      '4. Monitoreo en Tiempo Real.',
      '5. Sostenibilidad.',
      'Impacto Futuro en la Gestión Urbana.',
      'Recuperación de la Renta Inmobiliaria.',
    ],
    media: [
      { type: 'image', url: 'https://suriyaco.ar/images/gemelo.jpeg' },
      { type: 'video', url: 'https://suriyaco.ar/images/gemelodigital.MP4', poster: 'https://suriyaco.ar/images/gemelo.jpeg' },
    ],
  };

  return (
    <div className="success-story">
      <Container>
        <Card className="story-box shadow-lg p-4 mb-4">
          <Row>
            <Col>
              <h1 className="story-title">{story.title}</h1>
              <h2 className="story-subtitle">{story.subtitle}</h2>
              {story.description.map((paragraph, index) => (
                <p key={index} className="story-text">{paragraph}</p>
              ))}
            </Col>
          </Row>
          <Row>
            {story.media.map((mediaItem, index) => (
              <Col md={6} key={index} className="mb-4">
                {mediaItem.type === 'image' ? (
                  <Card className="media-card">
                    <Card.Img variant="top" src={mediaItem.url} />
                  </Card>
                ) : (
                  <Card className="media-card">
                    <video controls poster={mediaItem.poster} className="video-player">
                      <source src={mediaItem.url} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </Card>
                )}
              </Col>
            ))}
          </Row>
        </Card>
      </Container>
    </div>
  );
}

export default SuccessStory;
