import React from 'react';
import Slider from 'react-slick';
import './ImageSlider.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";



// Imágenes importadas
import levMasivo from './images/lev-masivo.jpg';
import recHidricos from './images/rec-hidricos.jpg';
import impactoAmbiental from './images/impacto-ambiental.jpg';
import reforestacion from './images/reforestacion.jpg';
import planificacionUrbana from './images/planificacion-urbana.jpg';
import conservacion from './images/conservacion.jpg';
import erosion from './images/erosion.jpg';
import emisiones from './images/emisiones.jpg';
import areasProtegidas from './images/areasProt.jpg';

const ImageSlider = () => {
  const settings = {
    dots: true, // Muestra puntos de navegación
    infinite: true, // El slider es infinito
    speed: 500, // Velocidad de transición
    slidesToShow: 1, // Mostrar una diapositiva a la vez
    slidesToScroll: 1, // Desplazarse una diapositiva por vez
    autoplay: true, // Autoplay habilitado
    autoplaySpeed: 3000, // Velocidad de autoplay
  };

  const slides = [
    {
      title: 'Implementar tecnologías de levantamiento masivo',
      description: 'Utilizar drones, satélites y sensores avanzados para mapear áreas naturales y detectar cambios en la biodiversidad, salud del suelo y masas forestales, permitiendo una respuesta rápida ante amenazas ambientales.',
      image: levMasivo,
    },
    {
      title: 'Optimizar la gestión de recursos hídricos',
      description: 'Emplear datos geoespaciales para monitorizar cuencas hidrográficas, prever inundaciones y gestionar el uso sostenible del agua, minimizando el impacto en ecosistemas acuáticos.',
      image: recHidricos,
    },
    {
      title: 'Evaluar y mitigar el impacto ambiental de actividades humanas',
      description: 'Utilizar levantamientos masivos para medir el impacto de proyectos mineros, agrícolas e industriales sobre el medio ambiente, promoviendo prácticas más sostenibles.',
      image: impactoAmbiental,
    },
    {
      title: 'Monitorear la deforestación y promover la reforestación',
      description: 'Usar levantamientos satelitales y de drones para identificar áreas afectadas por la deforestación y evaluar las oportunidades para reforestar de manera estratégica y eficiente.',
      image: reforestacion,
    },
    {
      title: 'Facilitar la planificación urbana sostenible',
      description: 'Aprovechar los levantamientos masivos para planificar ciudades más verdes y resilientes, identificando áreas para crear corredores ecológicos y zonas de amortiguamiento frente a la expansión urbana.',
      image: planificacionUrbana,
    },
    {
      title: 'Apoyar la conservación de especies en peligro',
      description: 'Utilizar datos de levantamientos masivos para mapear y monitorear hábitats críticos de especies amenazadas, facilitando su protección y conservación a través de la gestión adecuada de los territorios.',
      image: conservacion,
    },
    {
      title: 'Prevenir la erosión del suelo y restaurar áreas degradadas',
      description: 'Mediante levantamientos geológicos masivos, identificar áreas propensas a la erosión y desarrollar estrategias para restaurar la salud del suelo y prevenir la desertificación.',
      image: erosion,
    },
    {
      title: 'Monitorear y gestionar las emisiones de gases de efecto invernadero',
      description: 'Implementar sistemas avanzados de levantamientos para controlar las emisiones de CO₂ y otros gases contaminantes, promoviendo su reducción en zonas industriales y urbanas.',
      image: emisiones,
    },
    {
      title: 'Promover la creación de áreas protegidas',
      description: 'Usar datos obtenidos por levantamientos masivos para identificar regiones ecológicamente valiosas y apoyar la creación de nuevas áreas naturales protegidas.',
      image: areasProtegidas,
    },
  ];

  return (
    <div className="slider-container">
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <div key={index} className="slide">
            <img src={slide.image} alt={slide.title} className="slide-image" />
            <div className="slide-text">
              <h3>{slide.title}</h3>
              <p>{slide.description}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default ImageSlider;
